
import './App.css';
import logo from './res/logo.svg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faHome, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faInstagram, faTiktok, faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const icons = {
  mail: <FontAwesomeIcon icon={faEnvelope} />,
  phone: <FontAwesomeIcon icon={faPhone} />,
  address: <FontAwesomeIcon icon={faHome} />,
  facebook: <FontAwesomeIcon icon={faFacebookF} />,
  instagram: <FontAwesomeIcon icon={faInstagram} />,
  tiktok: <FontAwesomeIcon icon={faTiktok} />,
  whatsapp: <FontAwesomeIcon icon={faWhatsapp} />
};
const info = {
  phone: "320-911-2949",
  tel: "tel:3209112949",
  mail: "eurotaxicrotone.kr5@gmail.com",
  mailto: "mailto:eurotaxicrotone.kr5@gmail.com",
  address: "via dei delfini n°5 88900 Crotone (KR)",
  logoLink: "",
  maps: "https://www.google.com/maps/place/Via+dei+Delfini,+5,+88900+Gabella+VI+KR/@39.1525636,17.1130699,17z",
  facebook: "https://www.facebook.com/profile.php?id=100010453571953",
  instagram: "https://instagram.com/eurotaxicrotonese",
  tiktok: "https://www.tiktok.com/@il_tassista_di_tik_tok",
  whatsapp: "https://web.whatsapp.com/send?phone=393209112949"
};
const text = {
  call: "Chiama",
  companyName: "Euro taxi Crotonese",
};

const contactsTop = (
  <div className="contacts-top">
    <div className="flex wrap space-between">
      <div className="contacts-links">
        <a href={info.logoLink}>
          <span className="App-logo">
            <img src={logo} className="App-logo" alt="" />
          </span>
        </a>
        <a href={info.tel}>
          <span className="icon-btn">{icons.phone}</span>
          <span className="txt phone">{info.phone}</span>
        </a>
        <a href={info.mailto}>
          <span className="icon-btn">{icons.mail}</span>
          <span className="txt mail">{info.mail}</span>
        </a>
      </div>
      <div className="contacts-social">
        <a target="_blank" rel="noreferrer" href={info.facebook}>{icons.facebook}</a>
        <a target="_blank" rel="noreferrer" href={info.instagram}>{icons.instagram}</a>
        <a target="_blank" rel="noreferrer" href={info.whatsapp}>{icons.whatsapp}</a>
        <a target="_blank" rel="noreferrer" href={info.tiktok}>{icons.tiktok}</a>
      </div>
    </div>
  </div>
);

function App() {
  return (
    <div className="App">
      {contactsTop}
      <header className="App-header">
        <div className="align-center">
          <h1 className="nomargin">{text.companyName}</h1>
          <a className="phone-number" href={info.tel}>
            <h4 className="nomargin">{text.call}</h4>
            <h2 className="nomargin">{info.phone}</h2>
          </a>
        </div>
      </header>
      <div className="page"></div>
    </div>
  );
}

export default App;
